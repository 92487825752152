table {
    border-collapse: collapse;
    background-color: #FFFFFF;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.dataRow:nth-child(even) {
    background-color: #dddddd;
}

.dataRow:hover {
    background-color: #282828;
    color: #FFFFFF;
    cursor: pointer;
}